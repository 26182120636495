import * as React from 'react';

import benefit1 from './benefit1.svg';
import benefit2 from './benefit2.svg';
import benefit3 from './benefit3.svg';
import benefit4 from './benefit4.svg';
import benefit5 from './benefit5.svg';
import benefit6 from './benefit6.svg';
import benefit7 from './benefit7.svg';

let benefits = [
	{
		imgSrc: benefit1,
		alt: 'Tuition Reimbursement',
		text: 'Tuition Reimbursement'
	},
	{
		imgSrc: benefit2,
		alt: 'FREE Medical Insurance',
		text: 'FREE Medical Insurance'
	},
	{ imgSrc: benefit3, alt: '401k Match', text: '401k Match' },
	{ imgSrc: benefit4, alt: 'Competitive Pay', text: 'Competitive Pay' },
	{
		imgSrc: benefit5,
		alt: 'Perfect Attendance Bonus',
		text: 'Perfect Attendance Bonus'
	},
	{ imgSrc: benefit6, alt: 'Referral Program', text: 'Referral Program' },
	{ imgSrc: benefit7, alt: 'Referral Bonu', text: 'Referral Bonus' }
];

const Benefits: React.FC = () => {
	return (
		<section className={'bg-gp-green'}>
			<div className={'container mx-auto py-8 px-5 text-center'}>
				<h2 className={'pt-6 font-lato text-3xl font-bold text-white'}>
					Our Benefits
				</h2>
				<p className={'py-6 font-lato text-xl text-white'}>
					Genpak is pleased to offer a comprehensive benefits program
					to its valued employees which intended to enhance your life
					and those of your family members.
				</p>
				<div
					className={
						'flex flex-col justify-between gap-4 pt-10 md:flex-row'
					}
				>
					{benefits.map((benefit, index) => {
						return (
							<div className={'mb-4 flex flex-col items-center'}>
								<img
									width={40}
									src={benefit.imgSrc}
									alt={benefit.alt}
									className={'mb-6 min-h-[50px]'}
								/>
								<span
									className={
										'max-w-[130px] font-lato text-white'
									}
								>
									{benefit.text}
								</span>
							</div>
						);
					})}
				</div>
			</div>
		</section>
	);
};

export default Benefits;
