import * as React from 'react';

const JobCategories: React.FC = () => {
	return (
		<section className={'bg-gp-dark py-10'}>
			<div
				className={
					'container mx-auto flex flex-row flex-wrap justify-center gap-4 gap-x-10'
				}
			>
				<a
					href={'/search/?term='}
					className={
						'w-[200px] cursor-pointer rounded bg-white px-4 py-2 text-center font-lato font-bold text-gp-green hover:text-gp-green hover:opacity-90 focus:text-gp-green focus:opacity-90'
					}
				>
					Packers
				</a>
				<a
					href={'/search/?term='}
					className={
						'w-[200px] cursor-pointer rounded bg-white px-4 py-2 text-center font-lato font-bold text-gp-green hover:text-gp-green hover:opacity-90 focus:text-gp-green focus:opacity-90'
					}
				>
					Machine Operators
				</a>
				<a
					href={'/search/?term='}
					className={
						'w-[200px] cursor-pointer rounded bg-white px-2 py-2 text-center font-lato font-bold text-gp-green hover:text-gp-green hover:opacity-90 focus:text-gp-green focus:opacity-90'
					}
				>
					Maintenance Technicians
				</a>
				<a
					href={'/search/?term='}
					className={
						'w-[200px] cursor-pointer rounded bg-white px-4 py-2 text-center font-lato font-bold text-gp-green hover:text-gp-green hover:opacity-90 focus:text-gp-green focus:opacity-90'
					}
				>
					Quality Technicians
				</a>
				<a
					href={'/search/?term='}
					className={
						'w-[200px] cursor-pointer rounded bg-white px-4 py-2 text-center font-lato font-bold text-gp-green hover:text-gp-green hover:opacity-90 focus:text-gp-green focus:opacity-90'
					}
				>
					Production Supervisors
				</a>
				<a
					href={'/search/?term='}
					className={
						'w-[200px] cursor-pointer rounded bg-white px-4 py-2 text-center font-lato font-bold text-gp-green hover:text-gp-green hover:opacity-90 focus:text-gp-green focus:opacity-90'
					}
				>
					Managers/Directors
				</a>
				<a
					href={'/search/?term='}
					className={
						'w-[200px] cursor-pointer rounded bg-white px-4 py-2 text-center font-lato font-bold text-gp-green hover:text-gp-green hover:opacity-90 focus:text-gp-green focus:opacity-90'
					}
				>
					Finance
				</a>
				<a
					href={'/search/?term='}
					className={
						'w-[200px] cursor-pointer rounded bg-white px-4 py-2 text-center font-lato font-bold text-gp-green hover:text-gp-green hover:opacity-90 focus:text-gp-green focus:opacity-90'
					}
				>
					Human Resources
				</a>
				<a
					href={'/search/?term='}
					className={
						'w-[200px] cursor-pointer rounded bg-white px-4 py-2 text-center font-lato font-bold text-gp-green hover:text-gp-green hover:opacity-90 focus:text-gp-green focus:opacity-90'
					}
				>
					Customer Service
				</a>
				<a
					href={'/search/?term='}
					className={
						'w-[200px] cursor-pointer rounded bg-gp-green py-2 px-4 text-center font-lato font-bold text-white hover:text-white hover:opacity-90 focus:text-white focus:text-gp-green focus:opacity-90'
					}
				>
					View All Jobs
				</a>
			</div>
		</section>
	);
};

export default JobCategories;
