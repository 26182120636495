import * as React from 'react';

import card1 from './card1.svg';
import card2 from './card2.svg';
import card3 from './card3.svg';
import card4 from './card4.svg';
import card5 from './card5.svg';

interface Card {
	imgSrc: string;
	alt: string;
	title: string;
	text: string;
}

const cards: Card[] = [
	{
		imgSrc: card1,
		alt: 'Customers',
		title: 'Customers',
		text: 'Genpak is committed to developing the corporate leaders of tomorrow. Our program combines employee engagement with expert shadowing.'
	},
	{
		imgSrc: card2,
		alt: 'Employees',
		title: 'Employees',
		text: 'To provide a safe work environment that encourages trust, personal development and involvement.'
	},
	{
		imgSrc: card3,
		alt: 'Environment',
		title: 'Environment',
		text: 'To protect the environment as part of our business practices.'
	},
	{
		imgSrc: card4,
		alt: 'Community',
		title: 'Community',
		text: 'To conduct our business as model corporate citizens. To take a positive role in our working relationships with our neighbors.'
	},
	{
		imgSrc: card5,
		alt: 'Shareholders',
		title: 'Shareholders',
		text: 'To operate an efficient business that allows us to provide value to our customers and a fair return to our shareholders.'
	}
];

const OurFocus: React.FC = () => {
	return (
		<section className={'bg-gp-light-green'}>
			<div className={'container mx-auto px-5 py-10 text-center'}>
				<h2 className={'font-lato text-3xl font-bold text-body'}>
					Our Focus
				</h2>
				<div
					className={
						'my-12 flex flex-row flex-wrap justify-center gap-8 gap-x-6'
					}
				>
					{cards.map((card: Card) => {
						return (
							<div
								className={
									'flex flex-col items-center rounded bg-white p-8 shadow-lg'
								}
							>
								<div className={'min-h-[110px]'}>
									<img src={card.imgSrc} alt={card.alt} />
								</div>
								<span
									className={
										'pt-5 font-lato text-xl text-gp-green'
									}
								>
									{card.title}
								</span>
								<p
									className={
										'max-w-[290px] pt-4 font-lato text-lg'
									}
								>
									{card.text}
								</p>
							</div>
						);
					})}
				</div>
			</div>
		</section>
	);
};

export default OurFocus;
